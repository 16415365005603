import Swiper from 'swiper/bundle';
import { Pagination, Thumbs, Zoom } from 'swiper/modules';
import hljs from 'highlight.js';
import $ from "jquery";
import ClipboardJS from 'clipboard';
import 'slick-carousel';
import Lenis from '@studio-freight/lenis';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


document.addEventListener("DOMContentLoaded", () => {
  const currentUrl = window.location.href;
  const navLinks = document.querySelectorAll('#gnav .main li a');

  navLinks.forEach(link => {
    const linkUrl = new URL(link.getAttribute('href'), currentUrl).href; // Create a full URL for comparison
    // Check if the currentUrl exactly matches linkUrl, or if the currentUrl matches linkUrl followed by additional path segments
    if (currentUrl === linkUrl || currentUrl.startsWith(linkUrl) && currentUrl.charAt(linkUrl.length) === '/') {
      link.parentNode.classList.add('active');
    }
  });
});

const $button = $('#menuButton');
const $closeButton = $('#closeButton');
let $menuState = false;
let $scrollpos;
$(function(){
  // hamburger navi
  $button.on('click',function(){
    $(this).toggleClass('show');
    $('#gnav').addClass('show');
    if($menuState == false) {
      $scrollpos = $(window).scrollTop();
      $('body').addClass('fixed').css({'top': -$scrollpos});
      $menuState = true;
    } else {
      $('body').removeClass('fixed').css({'top': 0});
      window.scrollTo( 0 , $scrollpos );
      $menuState = false;
    }
  });
  $closeButton.on('click',function(){
    $('#gnav').removeClass('show');
    $('body').removeClass('fixed').css({'top': 0});
      window.scrollTo( 0 , $scrollpos );
      $menuState = false;
  });
  $('#gnav').find('a').on('click',function(){
    $('#gnav').toggleClass('show');
    $button.toggleClass('show');
    if($menuState == false) {
      $menuState = true;
    } else {
      $menuState = false;
    }
  });
  $(window).on('load resize',function(){
    if($(window).innerWidth() >= 1080){
      $('body').removeClass('fixed').css({'top': 0});
    };
  });
    // ドキュメント全体にクリックイベントリスナーを追加
    $(document).on('click', function(event) {
      if ($menuState && !$(event.target).closest($('#gnav')).length && !$(event.target).closest($button).length) {
        $('#gnav').removeClass('show');
        $button.removeClass('show');
        $menuState = false;
        $('body').removeClass('fixed').css({'top': 0});
        window.scrollTo( 0 , $scrollpos );
      }
    });

});

$('#gnav .main').find('.unit').hover(function(){
  $(this).find('.float').stop().toggleClass('active');
});


const $headerSearchButton = $('#headerSearchButton');
const $headerSearchCloseButton = $('#headerSearchCloseButton');
const $headerSearch = $('#headerSearch');
let $headerSearchState = false;

$(function(){
  // headerSearchButton
  $headerSearchButton.on('click',function(){
    $(this).toggleClass('show');
    $headerSearch.addClass('show');
    if($headerSearchState == false) {
      $scrollpos = $(window).scrollTop();
      $('body').addClass('fixed').css({'top': -$scrollpos});
      $headerSearchState = true;
    } else {
      $('body').removeClass('fixed').css({'top': 0});
      window.scrollTo( 0 , $scrollpos );
      $headerSearchState = false;
    }
  });
  $headerSearchCloseButton.on('click',function(){
    $headerSearch.removeClass('show');
    $('body').removeClass('fixed').css({'top': 0});
      window.scrollTo( 0 , $scrollpos );
      $headerSearchState = false;
  });
  $headerSearch.find('a').on('click',function(){
    $headerSearch.toggleClass('show');
    $headerSearchButton.toggleClass('show');
    if($headerSearchState == false) {
      $headerSearchState = true;
    } else {
      $headerSearchState = false;
    }
  });
  $(window).on('load resize',function(){
    if($(window).innerWidth() >= 1080){
      $('body').removeClass('fixed').css({'top': 0});
    };
  });

  // ドキュメント全体にクリックイベントリスナーを追加
  $(document).on('click', function(event) {
    if ($headerSearchState && !$(event.target).closest($headerSearch).length && !$(event.target).closest($headerSearchButton).length) {
      $headerSearch.removeClass('show');
      $headerSearchButton.removeClass('show');
      $headerSearchState = false;
      $('body').removeClass('fixed').css({'top': 0});
      window.scrollTo( 0 , $scrollpos );
    }
  });
});


let startPos = 0;
let winScrollTop = 0;
const header = document.querySelector('header');
let menuState = false;
window.addEventListener('scroll', function () {
  winScrollTop = window.scrollY;
  if (winScrollTop !== 0) {
    header.classList.add('is-scroll');
  } else {
    header.classList.remove('is-scroll');
  }
  if (winScrollTop >= startPos) {
    if (winScrollTop >= 200 && !menuState) {
      header.classList.add('is-down');
    }
  } else {
    header.classList.remove('is-down');
  }
  startPos = winScrollTop;
});


const $localnav = $('.localnav .overflow');
const $localnavTarget = $localnav.find('.active');
if($localnavTarget.length){
  $(window).on('load resize', function(){
    const $targetPos = $localnavTarget.position().left;
    const $targetWidth = $localnavTarget.width();
    const $winWidth = $(window).innerWidth();
    $localnav.scrollLeft(($targetPos - ($winWidth / 2)) + ($targetWidth / 2));
  });
}

document.addEventListener('DOMContentLoaded', function () {
  const target = document.querySelector('.type_anchor .inner');
  if (target) {
    const titles = document.querySelectorAll('.anchor');
    let anctext = '';
    titles.forEach(function (title) {
      const id = title.getAttribute('id');
      const nextText = title.nextElementSibling.textContent.trim();
      anctext += `<li><a href="#${id}">${nextText}</a></li>`;
    });
    target.innerHTML = `<ul>${anctext}</ul>`;
  }
});

$(document).ready(function(){
	const $slider = $('.static.slider, .static.slider_typeB');
  if($slider.length){
    $slider.slick({
  	  centerMode: true,
  		variableWidth: true,
  		dots: true,
  		autoplay: false,
    	autoplaySpeed: 6000,
  		speed: 800,
      pauseOnFocus: false,
      pauseOnHover: false,
      adaptiveHeight: true,
      arrows: false,
  		prevArrow: '<div class="slick-arrow"><img src="/images/mv_arrow.svg" alt="" /></div>',
  		nextArrow: '<div class="slick-arrow slick-next"><img src="/images/mv_arrow.svg" alt="" /></div>',
  	  responsive: [
  	    {
  	      breakpoint: 1024,
  	      settings: {
  					centerMode: false,
  					variableWidth: false,
  					prevArrow: '<div class="slick-arrow"><img src="/images/mv_arrow.svg" alt="" /></div>',
  					nextArrow: '<div class="slick-arrow slick-next"><img src="/images/mv_arrow.svg" alt="" /></div>',
  	      }
  	    }
  	  ]
  	});
  	$slider.on("beforeChange", (event, { slideCount: count }, slick, currentSlide, nextSlide) => {
  	  $slider.find(".slick-slide").each((index, el) => {
  	    const $this = $(el), slickindex = $this.attr("data-slick-index");
  	    if (nextSlide == slick.slideCount - 1 && currentSlide == 0) {
  	      if (slickindex == "-1") {
  	        $this.addClass("is-active-next");
  	      } else {
  	        $this.removeClass("is-active-next");
  	      }
  	    } else if (nextSlide == 0) {
  	      if (slickindex == slick.slideCount) {
  	        $this.addClass("is-active-next");
  	      } else {
  	        $this.removeClass("is-active-next");
  	      }
  	    } else {
  	      $this.removeClass("is-active-next");
  	    }
  	  });
  	});
    $slider.on("afterChange", (event, { slideCount: count }, slick, currentSlide, nextSlide) => {
      $(".slick-slide").removeClass('slick-now');
      $(".slick-active").addClass('slick-now');
  	});
    $('[data-slick-index="0"]').addClass('slick-now');
  }
});


$(function(){
  const $faq = $('.type_faq');
  if($faq.length) {
    // body に .guide クラスが付与されている場合、あらかじめ q に active クラスを付与しておく。また、next() をslideToggle() で開いておく
    if($('body').hasClass('guide')) {
      $faq.find('.unit.order').show();
      $faq.find('.q').addClass('active').next().show();
    };
    $faq.find('.q').on('click', function(){
      $(this).toggleClass('active').next().slideToggle();
    });
  };
});

// Extended Title
document.addEventListener('DOMContentLoaded', function () {
  const extendedTitle = document.querySelector('.type_extendedtitle');
  if (extendedTitle) {
    const h1 = extendedTitle.querySelector('h1');
    const span = extendedTitle.querySelector('span');
    if (h1) {
      h1.classList.add('loaded');
    }
    if (span) {
      span.classList.add('loaded');
    }
  }
});

// member
const type_member = document.querySelector('.component.type_member');

if (type_member) {
  const units = type_member.querySelectorAll('.inner .unit');
  units.forEach(unit => {
    const childCount = unit.querySelectorAll(':scope > *').length;
    unit.style.gridRow = `span ${childCount}`;
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const amountWrappers = document.querySelectorAll('.amountWrapper');

  amountWrappers.forEach(wrapper => {
      const addButton = wrapper.querySelector('.amount-add');
      const subButton = wrapper.querySelector('.amount-sub');
      const amountInput = wrapper.querySelector('.amount');

      addButton.addEventListener('click', () => {
          amountInput.value = parseInt(amountInput.value, 10) + 1;
      });

      subButton.addEventListener('click', () => {
          if (parseInt(amountInput.value, 10) > 1) {
              amountInput.value = parseInt(amountInput.value, 10) - 1;
          }
      });
  });
});

window.addEventListener('load', () => {
  const floatButtons = document.querySelector('.floatButtons');
  const printButton = document.querySelector('.printButton');
  if (printButton) {
    const printButtonY = printButton.getBoundingClientRect().top;
    window.addEventListener('scroll', () => {
      const windowHeight = window.innerHeight;
      if (window.scrollY + windowHeight > printButtonY) {
        floatButtons.classList.remove('is-show');
      } else {
        floatButtons.classList.add('is-show');
      }
    });
  }
});
window.addEventListener('load', () => {
  const floatButton = document.querySelector('.floatButton');
  const footer = document.querySelector('footer');
  if (floatButton) {
    const footerY = footer.offsetTop;
    window.addEventListener('scroll', () => {
      const windowHeight = window.innerHeight;
      if (window.scrollY + windowHeight > footerY) {
        floatButton.classList.remove('is-show');
      } else {
        floatButton.classList.add('is-show');
      }
      console.log(windowHeight + window.scrollY, footerY);
    });
  }
});

document.addEventListener('DOMContentLoaded', function() {
  const sidebar = document.querySelector('#sidebar');
  const sidebarButton = document.querySelector('#sidebar-menu-button');
  const gnav = document.querySelector('#gnav');
  const body = document.querySelector('body');
  const menuButton = document.querySelector('#menuButton');
  if(sidebarButton){
    sidebarButton.addEventListener('click', () => {
      sidebar.classList.toggle('is-show');
      sidebarButton.classList.toggle('is-active');
      body.classList.toggle('is-fixed');
      menuButton.classList.remove('is-show');
      gnav.classList.remove('is-show');
    });
  }
});


if (document.querySelector('#banner-close')) {
  document.querySelector('#banner-close').addEventListener('click', () => {
    const banner = document.querySelector('#banner');
    banner.style.display = 'none';
  });
  window.addEventListener('scroll', () => {
    const footer = document.querySelector('footer');
    const banner = document.querySelector('#banner');
    const footerRect = footer.getBoundingClientRect();
    const windowHeight = window.innerHeight;
  
    if (footerRect.top < windowHeight) {
        banner.classList.add('is-hide');
    } else {
        banner.classList.remove('is-hide');
    }
  });
}

const sliderThumbnail = new Swiper(".choice-btn", {
  spaceBetween: 0,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
});

const swiper = new Swiper('.swiper-main-images', {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  effect: 'fade',
  zoom: true,
  fadeEffect: {
    crossFade: true
  },
	autoplay: {
		delay: 3000,
		disableOnInteraction: false,
	},
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  thumbs: {
    swiper: sliderThumbnail,
  },
});

$(document).ready(function(){
  const $topbanner = $('#topbanner');
  if($topbanner.length){
    $topbanner.slick({
      centerMode: true,
      autoplay: true,
      variableWidth: true,
      autoplaySpeed: 5000,
      speed: 800,
      arrows: false,
      dots: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      slidesToShow: 3,
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
          fade: true,
        }
      }],
    });
  }
});

if (typeof hljs !== 'undefined') {
  hljs.highlightAll();
}

new ClipboardJS('.clipboard');

document.addEventListener('DOMContentLoaded', (event) => {
  const video = document.getElementById('top-mv-video');
  const muteButton = document.getElementById('mute');

  if (video) {
    // Check if the video element is supported
    const supportsVideo = !!document.createElement('video').canPlayType;
    if (supportsVideo) {
      // Set up custom controls if JavaScript is enabled
      video.controls = false;

      muteButton.addEventListener('click', () => {
        video.muted = !video.muted;
        muteButton.className = !video.muted ? 'sound-on' : 'sound-off';
      });
    }
  }
});

// lenis

const lenis = new Lenis();
function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

// Lenis smooth scrolling for anchor links, apply offset only if window width is less than 768px
function setupLenisSmoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (event) {
      event.preventDefault();
      const targetId = this.getAttribute('href').slice(1); 
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        let offset = 0;
        if (window.innerWidth < 768) {
          const headerHeight = document.querySelector('header').offsetHeight; // ヘッダーの高さを取得
          offset = -headerHeight; // 768px未満の場合にのみヘッダー分のオフセットを追加
        }
        lenis.scrollTo(targetElement, { offset: offset });
      }
    });
  });

	// #pagetopにクリックイベントを追加
	const pageTop = document.getElementById('pagetop');
	if (pageTop) {
		pageTop.addEventListener('click', () => {
			lenis.scrollTo(0);
		});
	} 
}

// 初回ロード時にLenisのスムーズスクロールをセットアップ
document.addEventListener('DOMContentLoaded', () => {
  setupLenisSmoothScroll();
});

$(function(){
  const $utils = $('#gnav .utils');
  if($utils.length) {
    $utils.find('h3').on('click', function(){
      $(this).toggleClass('active').next().slideToggle('fast');
    });
  };
});

$(function(){
  const $categories = $('#sidebar .categories');
  if($categories.length) {
    $categories.find('h3').on('click', function(){
      $(this).toggleClass('active').next().slideToggle('fast');
    });
  };
});

document.addEventListener('DOMContentLoaded', () => {
  const gnav = document.querySelector('#gnav');

  gnav.addEventListener('wheel', (event) => {
    event.stopPropagation(); // イベントの伝搬を停止
  });
});

class TabManager {
  constructor(container) {
      this.container = container;
      this.tabButtons = this.container.querySelectorAll('.tab-button');
      this.tabContents = this.container.querySelectorAll('.tab-content');
      this.bindEvents();
      this.showTab('tab1'); // デフォルトで最初のタブを表示
  }

  bindEvents() {
      this.tabButtons.forEach(button => {
          button.addEventListener('click', () => {
              const tabId = button.getAttribute('data-tab');
              this.showTab(tabId);
          });
      });
  }

  showTab(tabId) {
      this.tabButtons.forEach(button => {
          button.classList.toggle('active', button.getAttribute('data-tab') === tabId);
      });

      this.tabContents.forEach(content => {
          if (content.id === tabId) {
              content.classList.add('active');
              setTimeout(() => {
                  content.style.opacity = '1';
              }, 50);
          } else {
              content.style.opacity = '0';
              setTimeout(() => {
                  content.classList.remove('active');
              }, 100);
          }
      });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const tabContainer = document.getElementById('tab-container');
  if (tabContainer) {
    new TabManager(tabContainer);
  }
});

$(document).ready(function(){
  const $slideitem = $('.slideitem .list');
  if ($slideitem) {
    $slideitem.slick({
      centerMode: false,
      autoplay: false,
      variableWidth: true,
      speed: 800,
      arrows: true,
      dots: false,
      infinite: false,
      slidesToShow: 5,
  		prevArrow: '<div class="slick-arrow"><img src="/images/slideitem_arrow.svg" alt="" /></div>',
  		nextArrow: '<div class="slick-arrow slick-next"><img src="/images/slideitem_arrow.svg" alt="" /></div>',
      responsive: [{
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }],
    });
  }
});

